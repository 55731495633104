// roleperm

<template>
	<v-card dark tile flat>
		<v-toolbar
			color="indigo lighten-1"
			dark
			flat tile
		>
			<v-app-bar-nav-icon></v-app-bar-nav-icon>

			<v-toolbar-title>RolePermissions</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-btn icon>
				<v-icon>mdi-magnify</v-icon>
			</v-btn>

			<v-btn icon>
				<v-icon>mdi-dots-vertical</v-icon>
			</v-btn>

			<template v-slot:extension>
				<v-tabs
					v-model="tab"
					align-with-title
					color="indigo darken-4"
					background-color="transparent"
					slider-color="indigo darken-4"
					dark
					next-icon="mdi-arrow-right-bold-box-outline"
					prev-icon="mdi-arrow-left-bold-box-outline"
					show-arrows show-arrows-on-hover
				>
					<v-tab
						v-for="i in 30"
						:key="i"
					>
						Item {{ i }}
					</v-tab>
				</v-tabs>
			</template>
		</v-toolbar>
		<v-tabs-items v-model="tab">
			<v-tab-item
			v-for="i in 30"
			:key="i"
			>
				<v-card flat tile dark><v-layout><v-flex xs2>
                    <v-tabs vertical dark tile dense
                        v-model="tab2"
                        color="indigo darken-4"
                        background-color="indigo lighten-1"
                        slider-color="indigo darken-4"
                    >
                        <v-tab tile dense
                            v-for="i2 in 6"
                            :key="i2"
                        >
                            Option {{ i2 }}
                        </v-tab>
                    </v-tabs></v-flex><v-flex>
		            <v-tabs-items v-model="tab2">
                        <v-tab-item
                            v-for="i2 in 6"
                            :key="i2"
                        >
                            <v-card flat dark tile>
                                <v-card-text>
                                    <v-chip-group v-model="selection" multiple column
                                        :mandatory="false" active-class="primary--text">
                                        <v-chip v-for="(item,idx) in items" :key="idx" :value="idx" @click="onToggleRole()">
                                            <v-avatar v-if="isActive(idx)"><v-icon>check_circle_outline</v-icon></v-avatar>
                                            {{ item }}
                                        </v-chip>
                                    </v-chip-group>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
		            </v-tabs-items></v-flex></v-layout>
				</v-card>
			</v-tab-item>
		</v-tabs-items>
	</v-card>
</template>

<script>
	//import X from './x'
	//import { mapGetters } from 'vuex'
	export default {
	  	name: 'roleperm',
		components: {},
	  	props: {
	  		item: {
	  			type: Object,
	  			default () {
	  				return {}
	  			}
	  		},
	  	},
		data() {
			return {
                tab: null,
                tab2: null,
                items: [
                    'web', 'shopping', 'videos', 'images', 'news',
                ],
                selection: [],
            }
        },
		computed: {},
		methods: {
            onToggleRole() {
            
            },
            isActive(i) {
            
            },
        },
		mounted() {},
	  	watch: {},
    }
</script>

<style>
</style>